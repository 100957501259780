import type { Procedure } from 'o365-modules';

export function UpdateLkpSelection(procRecent: Procedure, dataObject : any, item: any, isCurrentRow: any = null) {
    if (!dataObject.loadRecents) return;
    if (dataObject && item.ID && typeof item.ID === 'number') {
        procRecent.execute({
            Record_ID: item.ID,
            ViewName: dataObject.viewName,
            Pinned: item._pinned
        });

        let ctCurrent = 0;
        for (const item of dataObject.data) {
            if (isCurrentRow?.(item)) {
                ctCurrent++;
            } else {
                break;
            }
        }

        //move to top to top
        if (item._pinned) {
            if (item.index > ctCurrent) {
                dataObject.reshiftItem(item.index, ctCurrent);
            }
        } else if (!isCurrentRow?.(item)) {
            dataObject.reshiftItem(item.index, 0);
        }
    }
}

export function PinnedClick(procRecent: Procedure, dataObject: any, item: any, isCurrentRow: any = null) {
    if (item._pinned === null) {
        item._pinned = false;
    }
    item._pinned = !item._pinned;
    UpdateLkpSelection(procRecent, dataObject, item, isCurrentRow);
}